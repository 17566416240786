const moment = require('moment');
const { fromString, isNonPayingPackage } = require('./planDetails');

const _calculatePaidUntil = (dateExpires) => {
  return moment.utc(dateExpires).startOf('day').subtract(14, 'days');
};

const _hasActiveSubscription = (dateExpires, currentPackageSku) => {
  if (isNonPayingPackage(currentPackageSku)) {
    return false;
  }

  const today = moment.utc().startOf('day');
  const paidUntil = _calculatePaidUntil(dateExpires);
  const hasExpired = paidUntil.isSameOrBefore(today);

  return hasExpired === false;
};

const _isMonthlyToAnnualCase = (currentPackageSku, toPackageSku) => {
  const fromPlan = fromString(currentPackageSku);
  const toPlan = fromString(toPackageSku);

  if (fromPlan.period === 1 && toPlan.period === 12) {
    return true;
  }
  return false;
};

const shouldChargeFlexiPayWithPackagePrice = ({ dateExpires, currentPackageSku, toPackageSku }) => {
  if (!currentPackageSku || !toPackageSku || !dateExpires || !moment(dateExpires).isValid()) {
    return false;
  }
  if (_isMonthlyToAnnualCase(currentPackageSku, toPackageSku)) {
    return true;
  }
  if (_hasActiveSubscription(dateExpires, currentPackageSku) === false) {
    return true;
  }

  return false;
};

module.exports = {
  shouldChargeFlexiPayWithPackagePrice,
};
