<template lang="pug">
.brand-wrapper.bg-light
  .container
    .row(v-if="loadingPlans" style="height: calc(100vh - 8rem)")
      loading-logo
    .row(v-else)
      .col-12.col-md-8
        .customer-data.d-flex.flex-column
          .customer-data-inner.d-flex.flex-wrap.justify-content-start.align-items-center
            .header-text.billing-address.justify-content-between
              .d-flex.flex-wrap.align-items-center
                billing-address-svg.header-text-icon
                span {{ $t('billingInfo') }}
              a.plan.plan-modify.cursor-pointer(
                v-if="isPreviousStep(progressStatuses.PROGRESS_BILLING)"
                @click.prevent="goToStep(progressStatuses.PROGRESS_BILLING)"
              ) {{ $t('modify') }}
            .d-flex.customer-data-content.flex-column(
              v-if="getProgress === progressStatuses.PROGRESS_BILLING"
            )
              checkout-billing(
                :loading="loadingBilling"
                :billing.sync="billing"
                @saveBilling="saveBilling"
              )
                template(#btnText) {{ $t('next') }}
          .customer-data-inner.d-flex.justify-content-start.align-items-center.flex-column
            .header-text.payment-method.justify-content-between
              .d-flex.flex-wrap.align-items-center
                payment-method-svg.header-text-icon
                span {{ $t('paymentMethod') }}
              a.plan.plan-modify.cursor-pointer(
                v-if="isPreviousStep(progressStatuses.PROGRESS_PAYMENT)"
                @click.prevent="goToStep(progressStatuses.PROGRESS_PAYMENT)"
              ) {{ $t('modify') }}
            .w-100(v-show="getProgress === progressStatuses.PROGRESS_PAYMENT")
              payment-choose(
                v-if="!loadingBilling"
                :region="getRegion"
                :billing="billing"
                :locale="$i18n.locale"
                :currentPaymentMethod="paymentMethod"
                :selectedPayment.sync="selectedPayment"
                :price="totalAmountFor3DS"
                :period="getPeriod"
                deletablePayments
                @save="savePaymentMethod"
                @payload="braintreePayload = $event.payload"
                @noPaymentMethodRequestable="noPaymentRequestable"
                @loaded="loadingSummary = false"
              )
                template(#btnText) {{ $t('next') }}
          .customer-data-inner.d-flex.justify-content-start.align-items-center.flex-column
            .header-text.order-summary.justify-content-between
              .d-flex.flex-wrap.align-items-center
                order-summary-svg.header-text-icon
                span {{ $t('orderSummary') }}
            .w-100(v-show="getProgress === progressStatuses.PROGRESS_SUMMARY")
              loading-logo(v-if="loadingSummary")
              div(v-show="!loadingSummary")
                .d-flex.customer-data-content
                  .row.col-8.p-0
                    .col-12
                      .row.order-summary-box.mb-3
                        .col-5
                          .order-summary-box-label {{ $t('billingInfo') }}
                        .col-7
                          .order-summary-box-data-title.mb-2 {{ getFullName }}
                          .order-summary-box-data-benefit(v-if="billing.billingName") {{ billing.billingName }}
                          .order-summary-box-data-benefit {{ billing.address }}
                          .order-summary-box-data-benefit {{ billing.city }}, {{ getCountryByCode(billing.countryCode).text }} {{ billing.postalCode }}
                          .order-summary-box-data-benefit(v-if="billing.euVatNumber") {{ billing.euVatNumber }}
                      .row.order-summary-box.mb-3
                        .col-5
                          .order-summary-box-label {{ $t('paymentMethod') }}
                        .col-7
                          .order-summary-box-data-payment {{ getPaymentSummary }}
                      .row.order-summary-box.mb-3
                        .col-5
                          .order-summary-box-label {{ $t('selectedPlan') }}
                        .col-7
                          .order-summary-box-data-title {{ getPlanNameToShow }}
                          .order-summary-box-data-desc {{ getPeriod && getPlan ? planOriginalPrice : '' }} / {{ $t(selectedPeriodLanguageKey) }}
                          .order-summary-box-data-desc(v-if="hasUpgrade") ({{ `${$t('billedNextOn')} ${formattedNextBillingDate}` }})

                      .row.order-summary-box.mb-3(v-if="hasUpgrade")
                        .col-5
                          .order-summary-box-label {{ $t('upgradePrice') }}
                        .col-7
                          .order-summary-box-data-title {{ formattedUpgradePrice }}

                      .row.order-summary-box.mb-3(v-if="hasValidCouponDiscountForSelectedPlan")
                        .col-5
                          .order-summary-box-label {{ $t('couponDetails.couponItemName') }}
                        .col-7
                          .order-summary-box-data-title {{ formattedDiscountString }}

                      .row.order-summary-box.mb-3(v-if="shouldChargeFlexiPay")
                        .col-5
                          .order-summary-box-label {{ $t('flexiPay') }}
                        .col-7
                          .order-summary-box-data-title {{ formattedFlexiPayTotal }}

                      .row.order-summary-box.mb-3(v-if="selectedPayment === 'bank_transfer'")
                        .col-5
                          .order-summary-box-label {{ $t('payment.banktransferCostName') }}
                        .col-7
                          .order-summary-box-data-title 390 Ft
                      .row.order-summary-box.mb-3(v-if="shouldDisplayVat")
                        .col-5
                          .order-summary-box-label {{ $t('vatAmount') }}
                        .col-7
                          .order-summary-box-data-title {{ formattedVatString }}

                      .row.order-summary-box.mb-3
                        .col-5
                          .order-summary-box-label {{ $t('total') }}
                        .col-7
                          .order-summary-box-data-title {{ formattedTotal }}

                  .col-4
                    .order-summary-box.order-summary-box-info.alert.alert-primary(
                      v-if="(!isPaying || isInactive) && invalidTerms"
                    )
                      .col-2.col-md-3.col-lg-2.p-0.mr-1.d-flex.justify-content-center
                        .fa.fa-exclamation-triangle
                      .col-10.col-md-9.col-lg-10.p-0
                        div {{ $t('errorTermsAndConditions') }}

                .d-flex.customer-data-content.flex-column
                  .d-flex.align-items-center(v-if="!isPaying || isInactive")
                    input#checkout-terms(type="checkbox" v-model="terms" name="checkbox" value="")
                    label.mb-0.ml-2(
                      for="checkout-terms"
                      v-html="$t('termsAndConditions', { link: $t('termsAndConditionsLink') })"
                    )
                      a.ml-1.font-weight-bold.customer-data-policy(href="#") Terms
                  .d-flex.mt-4.mb-2.align-items-center
                    om-button(primary @click.prevent="confirmOrder") {{ $t('buttonConfirmOrder') }}

      .col-12.col-md-4
        .plan-information.d-flex.flex-column.bg-white
          .plan.plan-information-title.d-flex.flex-row.justify-content-between.align-items-center
            .select-plan.plan-header {{ $t('selectedPlan') }}
            router-link(
              :to="{ name: 'plan_settings', params: { userId: getAccountIdFromCookie() } }"
            )
              .plan.plan-modify {{ $t('modify') }}
          .plan-information-box(v-if="getPlanWithDetails")
            .plan.plan-type {{ getPlanNameToShow }}
            .plan.plan-price.mb-1 {{ planOriginalPrice }} / {{ $t(selectedPeriodLanguageKey) }}
            .plan.plan-benefit(
              v-if="partnerTypeService.isResellerPartnerType() || partnerTypeService.isCustom()"
              v-html="$t('agencyPlanDescription', { noOfVisitors: addThousandSep(getPlanWithDetails.maxVisitors) })"
            )
            .plan.plan-benefit(
              v-else-if="!$store.getters.isPageViewBasedPackageByPlan(getPlan)"
              v-html="$t('planDescription', { maxDomains: getPlanWithDetails.maxDomains, noOfVisitors: addThousandSep(getPlanWithDetails.maxVisitors) })"
            )
            .plan.plan-benefit(
              v-else
              v-html="$t('freemiumPlanDescription', { maxSites: getPlanWithDetails.maxDomains, noOfPageViews: addThousandSep(getPlanWithDetails.maxVisitors) })"
            )
            .plan.plan-upgrade-info.mt-4(v-if="hasUpgrade")
              .plan-upgrade-info-title {{ $t('planUpgradeInfoBox.title') }}
              .plan-upgrade-info-description {{ $t('planUpgradeInfoBox.description') }}

        .plan-information.d-flex.flex-column.bg-white.mt-5(
          v-if="isEnabledCouponActivation && !getCouponCode"
        )
          .plan.plan-information-title
            .plan-header {{ $t('couponDetails.couponInputBoxTitle') }}
          .plan-information-box
            .row.plan-item-row
              .col-12.pb-1
                span.brand-under-heading-text {{ $t('couponDetails.couponInputLabel') }}
            .row.plan-item-row
              .col-8
                om-input#coupon-activation(
                  type="text"
                  v-model="couponInput"
                  name="coupon"
                  :disabled="couponActivationInProgress"
                  value=""
                )
              .col-4.text-right
                om-button(
                  secondary
                  :loading="couponActivationInProgress"
                  @click.prevent="activateCoupon"
                ) {{ $t('couponDetails.activationButton') }}
            .row.plan-item-row.mt-3(v-if="getActivationProblemNotificationType")
              .col-12(style="color: #b20000") {{ $t(`couponDetails.alert.failedActivation.${getActivationProblemNotificationType}`) }}

        .plan-information.d-flex.flex-column.bg-white.mt-5(v-if="getCouponCode")
          .plan.plan-information-title
            .row
              .plan-header.col-8 {{ $t('couponDetails.activatedCouponTitle') }}
              .col-4.pt-1.text-right
                .plan.plan-remove.cursor-pointer(
                  v-if="couponService.hasStartedCouponActivation()"
                  @click.prevent="resetStartedCouponActivation()"
                ) {{ $t('couponDetails.cancelCoupon') }}
          .plan-information-box.pb-5
            div(
              :class="hasValidCouponDiscountForSelectedPlan ? 'plan-green-box' : 'plan-gray-box'"
            )
              .row.plan-item-row
                .col-12.plan-item-col-bold {{ getCouponDescription }}
              .row.plan-item-row.mt-3(v-if="getCouponValidityPeriod")
                .col-12.d-flex.flex-column
                  .d-flex.flex-wrap.justify-content-between
                    .pr-3 {{ $t('couponDetails.validityPeriod') }}:
                    div {{ getCouponValidityPeriod.start }} - {{ getCouponValidityPeriod.end }}
            .row.plan-item-row.mt-3(v-if="hasValidCouponDiscountForSelectedPlan === false")
              .col-12(style="color: #b20000") {{ $t('couponDetails.alert.invalidForPlan') }}

        .plan-information.d-flex.flex-column.bg-white.mt-5
          .plan.plan-information-title
            .plan-header {{ $t('items') }}
          .plan-information-box
            template(v-if="hasUpgrade")
              .row.plan-item-row.mb-3
                .col-7 {{ $t('upgradePrice') }}
                .col-5.text-right {{ formattedUpgradePrice }}
            template(v-else)
              .row.plan-item-row.mb-3
                .col-7 {{ getPlanNameToShow }} {{ $t('plan.plan2') }}
                .col-5.text-right {{ planOriginalPrice }}
            template(v-if="hasValidCouponDiscountForSelectedPlan")
              .row.plan-item-row.mb-3
                .col-7 {{ $t('couponDetails.couponItemName') }}
                .col-5.text-right {{ formattedDiscountString }}

            template(v-if="shouldChargeFlexiPay")
              .row.plan-item-row.mb-3
                .col-7 {{ $t('flexiPay') }}
                .col-5.text-right {{ formattedFlexiPayTotal }}

            template(v-if="selectedPayment === 'bank_transfer'")
              .row.plan-item-row.mb-3
                .col-7 {{ $t('payment.banktransferCostName') }}
                .col-5.text-right 390 Ft

            template(v-if="shouldDisplayVat")
              .row.plan-item-row.mb-3
                .col-7 {{ $t('vatAmount') }}
                .col-5.text-right {{ formattedVatString }}

            .row.plan-item-row.mb-3
              .col-7.bt-1.pt-2 {{ $t('total') }}
              .col-5.bt-1.pt-2.text-right {{ formattedTotal }}
</template>

<script>
  import moment from 'moment';
  import GET_SALES_AUTH from '@/graphql/GetSalesAuth.gql';
  import { getAccountIdFromCookie, separatePrice } from '@/util';
  import { createNamespacedHelpers, mapState, mapGetters } from 'vuex';
  import { PaymentAdapter } from '@/services/paymentAdapter';
  import dateFormat from '@/mixins/dateFormat';
  import { get as _get } from 'lodash-es';
  import { getCountryByLocale } from '@/config/countries';
  import PaymentChoose from '@/components/Payment/PaymentChoose.vue';
  import CheckoutBilling from '@/components/Payment/CheckoutBilling.vue';
  import addressValidation from '@/mixins/address';
  import checkoutMixin from '@/mixins/checkout';
  import {
    calculateUpgradePrice,
    calculateRefund,
    canCalculateCorrectUpgradePrice,
  } from '@om/payment/src/helpers/upgradeCalculator';
  import { toString as buildSku } from '@om/payment/src/helpers/planDetails';
  import { shouldChargeFlexiPayWithPackagePrice } from '@om/payment/src/helpers/flexiPay';

  const {
    mapGetters: paymentGetters,
    mapState: paymentState,
    mapActions: paymentActions,
    mapMutations: paymentMutations,
  } = createNamespacedHelpers('payment');

  const CREDIT_CARD = 'CreditCard';

  export default {
    components: {
      PaymentChoose,
      CheckoutBilling,
    },
    mixins: [dateFormat, addressValidation, checkoutMixin],
    data() {
      return {
        debug: false,

        loadingBilling: true,
        loadingSummary: true,
        loadingPlans: true,
        PaymentAdapter: null,
        progressStatuses: {
          PROGRESS_BILLING: 'billing',
          PROGRESS_PAYMENT: 'payment',
          PROGRESS_SUMMARY: 'summary',
        },
        progress: 'billing',
        terms: false,
        invalidTerms: false,
        countries: [
          { text: 'Hungary', value: 97, code: 'HU' },
          { text: 'United States', value: 223, code: 'US' },
        ],
        selectedPayment: '',
        braintreePayload: null,
        isInactive: false,
        paymentError: null,
        teardownBraintree: null,
        tryCounter: 0,

        plan: '',
        period: 1,
        couponInput: '',
        couponActivationInProgress: false,
      };
    },

    computed: {
      ...mapState(['partnerTypeService']),
      ...mapGetters([
        'isPageViewBasedPackage',
        'getRegion',
        'isPaying',
        'hasActiveSubscription',
        'currentPlan',
        'currentPeriod',
        'accountFeatures',
      ]),
      ...paymentGetters([
        'isShopifyPayment',
        'getPlainPlanName',
        'getPlans',
        'hasPaymentMethod',
        'getPlanDetail',
        'getFlexiPayTotalInHuf',
        'hasFuturePackage',
      ]),
      ...paymentState(['billing', 'paymentMethod', 'dateExpires', 'couponService']),
      hasUpgrade() {
        // TODO[Dzsi] Downgrade from master to other master
        return this.hasActiveSubscription;
      },
      getPlanNameToShow() {
        return this.getPlainPlanName(this.getPlan.toUpperCase());
      },
      getCountries() {
        return this.countries;
      },
      getCountryCode() {
        return this.billing.countryCode;
      },
      shouldDisplayVat() {
        if (this.billing.nonProfit) {
          return false;
        }
        const country = this.countries.find(({ code }) => code === this.billing.countryCode);
        let isEuVat = false;
        if (country) {
          isEuVat = country.euVatNumber;
        }
        return (
          this.billing.countryCode === 'HU' || (isEuVat && this.billing.euVatNumber.length === 0)
        );
      },
      getPlan() {
        return this.plan;
      },
      getPeriod() {
        return this.period;
      },
      toPackageSku() {
        const language = this.getRegion === 'Hungary' ? 'HU' : 'EN';
        return buildSku({ name: this.plan, language, period: this.period });
      },
      currentPackageSku() {
        const language = this.getRegion === 'Hungary' ? 'HU' : 'EN';
        return buildSku({ name: this.currentPlan, language, period: this.period });
      },
      getPaymentSummary() {
        const isBraintree = this.selectedPayment
          ? this.selectedPayment.indexOf('braintree') === 0
          : false;

        if (isBraintree) {
          const payload = this.braintreePayload || {
            type: '',
            details: { cardType: '', email: '' },
          };
          if (payload.type === CREDIT_CARD) {
            return `${payload.details.cardType} (${payload.details.lastFour})`;
          }
          return `${this.$t('payment.braintreepaypal')} (${payload.details.email})`;
        }

        return this.$t('payment.bank_transfer');
      },
      // Selected (to) period
      getPlanWithDetails() {
        const plan = this.getPlan.toUpperCase();
        const hasPlans = this.getPlans.length;

        if (hasPlans && plan) {
          const planDetails = this.getPlanDetail(plan);
          if (planDetails.price.annualOriginal) {
            // TODO refactor freemium plan prices to exclude periods
            planDetails.price.annual = planDetails.price.annual.split('/')[0].trim();
            planDetails.price.quarterly = planDetails.price.quarterly.split('/')[0].trim();
            planDetails.price.monthly = planDetails.price.monthly.split('/')[0].trim();
          }

          return planDetails;
        }

        return { price: { monthly: '', quarterly: '', annual: '' } };
      },
      planPrice() {
        return this.getPlanPriceString();
      },
      planOriginalPrice() {
        return this.getPlanOriginalPriceString();
      },
      fractionDigits() {
        return this.getRegion === 'Hungary' ? 0 : 2;
      },
      selectedPeriodKey() {
        return this.periodKeyByNumberOfMonths(this.getPeriod);
      },
      selectedPeriodLanguageKey() {
        let period = 'month';
        if (this.getPeriod === 12) {
          period = 'annual';
        } else if (this.getPeriod === 3) {
          period = 'quarter';
        }
        return period;
      },
      getFullName() {
        return `${this.billing.firstName} ${this.billing.lastName}`;
      },
      getProgress() {
        return this.progress;
      },
      isPreviousStep() {
        const self = this;

        const stepsOrder = {};
        const statuses = Object.values(self.progressStatuses);

        for (const index in statuses) {
          stepsOrder[statuses[index]] = parseInt(index, 10);
        }

        return (step) => {
          return stepsOrder[step] < stepsOrder[self.progress];
        };
      },
      totalAmountFor3DS() {
        const sum = this.calculateTotal;
        const roundedPrice =
          this.getRegion === 'Hungary' ? Math.round(sum) : parseFloat(sum.toFixed(2));

        if (this.debug) console.log('3DS total: ', roundedPrice);

        return roundedPrice;
      },
      calculateDiscount() {
        if (!this.hasValidCouponDiscountForSelectedPlan) return 0;

        const toPlanDetails = this.getPlanWithDetails;
        const { priceNumber: originalPrice } = this.getPlanOriginalPrice(toPlanDetails);

        if (this.hasUpgrade && this.upgradePrice) {
          const currency = this.getRegion === 'Hungary' ? 'HUF' : 'USD';
          return this.couponService.calculateDiscountForUpgradePrice(
            toPlanDetails.name,
            this.getPeriod,
            originalPrice,
            this.upgradePrice,
            currency,
          );
        }
        const { priceNumber: discountedPrice } = this.getPlanPrice(toPlanDetails);

        return originalPrice - discountedPrice;
      },
      calculateTotal() {
        let { priceNumber: planPrice } = this.getPlanOriginalPrice();
        if (this.hasUpgrade && this.upgradePrice) {
          planPrice = this.upgradePrice;
        }
        const discount = this.calculateDiscount;
        const flexiPay = this.shouldChargeFlexiPay ? this.flexiPayTotal : 0;
        const paymentPrice = this.selectedPayment === 'bank_transfer' ? 390 : 0;
        const vat = this.calculateVat;

        const sum = planPrice - discount + paymentPrice + flexiPay + vat;

        if (this.debug)
          console.log('total: ', { sum, planPrice, discount, flexiPay, paymentPrice, vat });

        return sum;
      },
      calculateVat() {
        if (!this.shouldDisplayVat) {
          return 0;
        }

        let priceNumber;
        if (this.hasUpgrade && this.upgradePrice) {
          priceNumber = this.upgradePrice;
        } else {
          ({ priceNumber } = this.getPlanOriginalPrice());
        }

        const discount = this.calculateDiscount;
        const flexiPay = this.shouldChargeFlexiPay ? this.flexiPayTotal : 0;
        const paymentMethodPrice = this.selectedPayment === 'bank_transfer' ? 390 : 0;

        const sum = (priceNumber - discount + flexiPay + paymentMethodPrice) * 0.27;

        if (this.debug) console.log('vat: ', { sum, planPrice: priceNumber, flexiPay });

        return sum;
      },
      shouldChargeFlexiPay() {
        const dateExpires = this.dateExpires;
        const currentPackageSku = this.currentPackageSku;
        const toPackageSku = this.toPackageSku;
        const shouldChargeFlexiPay = shouldChargeFlexiPayWithPackagePrice({
          dateExpires,
          currentPackageSku,
          toPackageSku,
        });

        if (!shouldChargeFlexiPay) return false;

        return this.flexiPayTotal > 0;
      },
      flexiPayTotal() {
        let total = this.getFlexiPayTotalInHuf;
        if (this.getRegion !== 'Hungary') {
          total = parseFloat((total * 0.004).toFixed(2));
        }
        return total;
      },
      upgradePrice() {
        const toPlanDetails = this.getPlanWithDetails;
        const toPeriod = this.getPeriod;
        const currentPlanDetails = this.getPlanDetail(this.currentPlan);
        const currentPeriod = this.currentPeriod;

        const toPlanPrice = this.getOriginalPlanPriceInHuf(toPlanDetails, toPeriod);
        const currentPlanPrice = this.getOriginalPlanPriceInHuf(currentPlanDetails, currentPeriod);

        let upgradePrice;

        if (this.isMonthlyToAnnualCase(currentPeriod, toPeriod)) {
          const refund = calculateRefund({
            dateExpires: this.dateExpires,
            packagePrice: currentPlanPrice,
            period: currentPeriod,
          });
          upgradePrice = refund < toPlanPrice ? toPlanPrice - refund : 0;
        } else {
          upgradePrice = calculateUpgradePrice({
            dateExpires: this.dateExpires,
            period: this.period,
            currentPackagePrice: currentPlanPrice,
            newPackagePrice: toPlanPrice,
          });
        }

        if (this.getRegion !== 'Hungary') {
          upgradePrice /= 250;
        }

        return upgradePrice;
      },
      formattedTotal() {
        const { prefix, suffix } = this.getPlanPrice();
        const sum = this.calculateTotal;
        const roundedPrice = this.getRegion === 'Hungary' ? Math.round(sum) : sum.toFixed(2);
        return `${prefix} ${this.$options.filters.thousandSep(roundedPrice, ' ')} ${suffix}`;
      },
      formattedUpgradePrice() {
        const { prefix, suffix } = this.getPlanPrice();
        return this.formatPrice(
          { prefix, priceNumber: this.upgradePrice, suffix },
          this.getRegion !== 'Hungary',
        );
      },
      formattedNextBillingDate() {
        const currentPeriod = this.currentPeriod;
        const toPeriod = this.getPeriod;
        if (this.isMonthlyToAnnualCase(currentPeriod, toPeriod)) {
          return this.formatDate(moment.utc().add(toPeriod, 'months').toDate());
        }
        return this.formatDate(moment.utc(this.dateExpires).subtract(14, 'days').toDate());
      },
      formattedDiscountString() {
        const { prefix, suffix } = this.getPlanPrice();
        const vat = this.calculateDiscount;
        const roundedPrice =
          this.getRegion === 'Hungary' ? Math.round(vat) : parseFloat(vat.toFixed(2));
        return `- ${this.formatPrice({ prefix, priceNumber: roundedPrice, suffix })}`;
      },
      formattedVatString() {
        const { prefix, suffix } = this.getPlanPrice();
        const vat = this.calculateVat;
        const roundedPrice =
          this.getRegion === 'Hungary' ? Math.round(vat) : parseFloat(vat.toFixed(2));
        return this.formatPrice({ prefix, priceNumber: roundedPrice, suffix });
      },
      formattedFlexiPayTotal() {
        const { prefix, suffix } = this.getPlanPrice();
        const flexiPayTotal = this.flexiPayTotal;
        return this.formatPrice({ prefix, priceNumber: flexiPayTotal, suffix });
      },
      _canCalculateCorrectUpgradePrice() {
        return canCalculateCorrectUpgradePrice({
          currentPlan: this.currentPlan,
          dateExpires: this.dateExpires,
          period: this.currentPeriod,
          isShopify: false,
        });
      },
      isEnabledCouponActivation() {
        if (this.hasFuturePackage) return false;
        return this.couponService.isEnabledCouponActivation();
      },
      getCouponCode() {
        if (this.hasFuturePackage) return false;
        if (this.couponService.hasValidCoupon() === false) return null;
        const code = this.couponService.getCouponCode();
        return code;
      },
      getCouponCodeWithPlanValidation() {
        if (this.hasFuturePackage) return null;
        if (this.hasValidCouponDiscountForSelectedPlan === false) return null;
        return this.getCouponCode;
      },
      getCouponDescription() {
        const languageKey = this.$i18n.locale === 'hu' ? 'hu' : 'en';
        return this.couponService.getCouponDescription(languageKey);
      },
      getCouponValidityPeriod() {
        const dates = this.couponService.getCouponValidityPeriod();
        if (!dates) return '';
        const start = this.formatDate(dates.start);
        const end = this.formatDate(dates.end);
        return { start, end };
      },
      hasValidCouponDiscountForCurrentPlan() {
        if (this.couponService.hasValidCoupon() === false) return false;
        const planDetails = this.getPlanDetail(this.currentPlan);
        return this.hasDiscountForPlan(planDetails, this.currentPeriod);
      },
      hasValidCouponDiscountForSelectedPlan() {
        if (this.couponService.hasValidCoupon() === false) return false;
        const planDetails = this.getPlanDetail(this.getPlan);
        return this.hasDiscountForPlan(planDetails, this.getPeriod);
      },
      getActivationProblemNotificationType() {
        const type = this.couponService.getNotificationType();
        if (!type || type.match(/^activation.*$/) === null) return null;
        return type;
      },
    },

    async created() {
      if (this.isShopifyPayment) {
        this.goToPlanSettings({ checkoutProblem: 'shopify' });
        return;
      }

      if (!this._canCalculateCorrectUpgradePrice) {
        this.goToPlanSettings({ checkoutProblem: 'upgrade' });
        return;
      }

      const packageParam = this.$route.query.package;

      if (packageParam) {
        const tmp = packageParam.split('-');
        this.plan = tmp[0];
        this.period = parseInt(tmp[1] || 1, 10);
      } else {
        await this.loadBilling();
        this.goToPlanSettings({ checkoutProblem: 'param' });
        return;
      }

      const tryParam = this.$route.query.try;

      if (tryParam) {
        this.tryCounter = tryParam;
      }

      const total = this.calculateTotal;
      if (!total || total < 0) {
        this.goToPlanSettings({ checkoutProblem: 'total' });
      }
    },

    async mounted() {
      await this.loadBilling();
      await this.loadPlans();
      this.loadingPlans = false;

      this.selectedPayment = this.paymentMethod || '';

      if (this.paymentMethod === 'bank_transfer') {
        // If someone is on the checkout and using bank transfer previously to pay
        // than only package change and payment method change is allowed
        this.selectedPayment = 'braintree';
      }

      // For new users
      if (!this.paymentMethod) {
        this.selectedPayment = 'braintree';

        if (this.getPeriod === 12 && this.getRegion === 'Hungary') {
          this.selectedPayment = '';
        }
      }

      if (this.paymentMethod && this.paymentMethod.indexOf('braintree') === 0) {
        this.$set(this, 'isInactive', _get(this.paymentData, 'expiredSubscription') === true);
      }

      this.loadingBilling = false;

      this.$set(
        this,
        'countries',
        getCountryByLocale(this.$i18n.locale).sort((a, b) => (a.text < b.text ? -1 : 1)),
      );

      const {
        data: { salesAuth },
      } = await this.$apollo.query({ query: GET_SALES_AUTH });
      this.$set(
        this,
        'PaymentAdapter',
        new PaymentAdapter(salesAuth, this.accountFeatures, this.$apollo),
      );

      if (
        this.$route.params.step &&
        Object.values(this.progressStatuses).indexOf(this.$route.params.step)
      ) {
        this.goToStep(this.$route.params.step);
      }
    },

    methods: {
      ...paymentActions(['loadPlans', 'loadBilling']),
      ...paymentMutations(['setPlan', 'setPlans', 'setPeriod', 'setBilling']),
      addThousandSep(value) {
        return this.$options.filters.thousandSep(value);
      },
      getPlanPriceString() {
        return this.formatPrice(this.getPlanPrice());
      },
      getPlanOriginalPriceString() {
        return this.formatPrice(this.getPlanOriginalPrice());
      },
      getPlanPrice(planDetails) {
        if (!planDetails) planDetails = this.getPlanWithDetails;
        return separatePrice(
          this.getPeriod && this.getPlan ? planDetails.price[this.selectedPeriodKey] : '',
        );
      },
      getPlanOriginalPrice(planDetails) {
        if (!planDetails) planDetails = this.getPlanWithDetails;
        if (!planDetails?.originalPrice) {
          return this.getPlanPrice(planDetails);
        }
        return separatePrice(
          this.getPeriod && this.getPlan ? planDetails.originalPrice[this.selectedPeriodKey] : '',
        );
      },
      formatPrice({ prefix, priceNumber, suffix }, fixed = false) {
        if (fixed) priceNumber = priceNumber?.toFixed(this.fractionDigits);
        return `${prefix} ${this.$options.filters.thousandSep(priceNumber, ' ')} ${suffix}`;
      },

      saveBilling(billingData) {
        this.setBilling(billingData);
        this.progress = this.progressStatuses.PROGRESS_PAYMENT;
      },

      async confirmOrder() {
        this.$v.$touch();

        const prevPackage = this.currentPlan;
        if (!this.terms && !this.isPaying) {
          this.invalidTerms = true;
          return;
        }

        const paymentMethod = !this.braintreePayload
          ? 'bank_transfer'
          : this.braintreePayload.type === CREDIT_CARD
          ? 'braintree'
          : 'braintreepaypal';

        const data = {
          paymentMethod,
          plan: this.getPlan,
          period: this.getPeriod,
          flexiPay: this.shouldChargeFlexiPay ? this.getFlexiPayTotalInHuf : 0,
          ...this.billing,
        };
        const country = this.getCountryByCode(data.countryCode);

        if (!country) {
          return; // No such country
        }

        data.country = country.value;

        if (this.braintreePayload) {
          data.paymentNonce = this.braintreePayload.nonce;
        }

        data.couponCode = this.getCouponCodeWithPlanValidation;

        this.loadingSummary = true;
        let createOrderResponse;

        try {
          createOrderResponse = await this.PaymentAdapter.sendCreateOrder(data);
        } catch (e) {
          this.$notify({
            type: 'error',
            text: 'Something went wrong!',
          });
          this.loadingSummary = false;
          console.error(e);
          return;
        }

        if (createOrderResponse.data.success) {
          let confirmOrder;
          try {
            const confirmOrderResponse = await this.PaymentAdapter.sendConfirmOrder(
              data.paymentMethod,
              data,
            );
            confirmOrder = confirmOrderResponse.data;
          } catch (e) {
            this.$notify({
              type: 'error',
              text: 'Something went wrong!',
            });
            this.loadingSummary = false;
            console.error(e);
            return;
          }

          if (confirmOrder.success) {
            this.$notify({
              type: 'success',
              text: this.$t('notifications.successfulOrder'),
            });

            this.couponService.afterOrderActions();

            if (confirmOrder.bankTransferToBraintree) {
              this.$store.commit('setBankTransferToBraintreeNotification', true);
            } else if (paymentMethod === 'bank_transfer') {
              this.$store.commit('setBankTransferNotification', true);
            }

            window.dataLayer.push({
              event: 'Successful order',
              paymentMethod,
              newPackage: this.getPlan,
              period: this.getPeriod,
              previousPackage: prevPackage,
            });

            await this.goToSuccessPage(this.getPlan);
          } else {
            let error = confirmOrder.message || confirmOrder.error;

            if (confirmOrder.reason) {
              error = `${error}\n${confirmOrder.reason}\n${confirmOrder.reasonDescription}`;
            } else if (confirmOrder.response?.message?.indexOf('payment_method_nonce') === -1) {
              error = `${error}\n${confirmOrder.response.message}`;
            }
            this.$notify({
              type: 'error',
              text: error,
              duration: 15000,
            });

            await this.$router.replace({
              name: 'checkout',
              params: { step: 'payment' },
              query: { package: `${this.getPlan}-${this.getPeriod}`, try: this.tryCounter + 1 },
            });
          }

          this.loadingSummary = false;
        } else {
          this.$notify({
            type: 'error',
            text: 'Something went wrong!',
          });
          this.loadingSummary = false;
        }
      },

      goToPlanSettings(query) {
        if (query.checkoutProblem) {
          this.$notify({
            type: 'error',
            text: 'Something went wrong!',
            duration: 15000,
          });
        }
        this.$router.replace({
          name: 'plan_settings',
          params: { userId: getAccountIdFromCookie() },
          query,
        });
      },

      getAccountIdFromCookie() {
        return getAccountIdFromCookie();
      },

      getCountryByCode(code) {
        for (const index in this.countries) {
          if (this.countries[index].code === code) {
            return this.countries[index];
          }
        }

        return false;
      },

      isBillingFilled() {
        return (
          this.billing.firstName &&
          this.billing.lastName &&
          this.billing.address &&
          this.billing.city &&
          this.billing.postalCode
        );
      },

      savePaymentMethod(data) {
        this.braintreePayload = data.payload;
        this.selectedPayment = data.method;
        this.progress = this.progressStatuses.PROGRESS_SUMMARY;
      },

      goToStep(stepName) {
        this.progress = stepName;
      },

      setPaymentError(error) {
        this.paymentError = error;
      },

      noPaymentRequestable() {
        if (this.isBillingFilled && this.getProgress === this.progressStatuses.PROGRESS_SUMMARY) {
          this.goToStep(this.progressStatuses.PROGRESS_PAYMENT);
        }
      },

      periodKeyByNumberOfMonths(periodInMonth) {
        let period = 'monthly';
        if (periodInMonth === 12) {
          period = 'annual';
        } else if (periodInMonth === 3) {
          period = 'quarterly';
        }
        return period;
      },

      getPlanPriceInHUF(planDetails, periodInMonth) {
        if (!planDetails) planDetails = this.getPlanWithDetails;
        const periodKey = this.periodKeyByNumberOfMonths(periodInMonth);
        let { priceNumber } = separatePrice(planDetails.price[periodKey]);
        if (this.getRegion !== 'Hungary') {
          priceNumber *= 250;
        }

        return priceNumber;
      },

      getOriginalPlanPriceInHuf(planDetails, periodInMonth) {
        if (!planDetails) planDetails = this.getPlanWithDetails;
        const periodKey = this.periodKeyByNumberOfMonths(periodInMonth);
        const priceString = planDetails.originalPrice[periodKey];
        let { priceNumber } = separatePrice(priceString);
        if (this.getRegion !== 'Hungary') {
          priceNumber *= 250;
        }

        return priceNumber;
      },

      hasDiscountForPlan(plan, periodInMonth) {
        const periodKey = this.periodKeyByNumberOfMonths(periodInMonth);
        return plan.hasDiscount && plan.hasDiscount[periodKey];
      },

      isMonthlyToAnnualCase(fromPeriod, toPeriod) {
        if (fromPeriod === 1 && toPeriod === 12) {
          return true;
        }

        return false;
      },

      async activateCoupon() {
        this.couponActivationInProgress = true;
        const isSuccess = await this.couponService.activateCoupon(this.couponInput);
        if (isSuccess) {
          this.$router.replace({
            name: 'checkout',
            params: { step: this.$route.params.step },
            query: {
              package: `${this.getPlan}-${this.getPeriod}`,
              activatedCoupon: this.couponInput,
            },
          });
        }
        this.couponActivationInProgress = false;
      },

      resetStartedCouponActivation() {
        this.couponService.resetStartedCouponActivation();
        this.$router.replace({
          name: 'checkout',
          params: { step: this.$route.params.step },
          query: { package: `${this.getPlan}-${this.getPeriod}`, deactivatedCoupon: true },
        });
      },
    },
  };
</script>
